<template>
  <div>
    <BaseTable ref="baseTable" :block="block" :showCheckboxes="true">
    </BaseTable>
  </div>
</template>

<script>
import BaseTable from './BaseTable.vue';

export default {
  components: {
    BaseTable
  },
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  methods: {
    getSelectedRows() {
      // Return the selected items from BaseTable
      return this.$refs.baseTable.selectedItems || [];
    }
  }
};
</script>
