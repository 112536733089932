import Facility from '../views/Wetlab/ModelViews/Facility.vue'
import Kit from '../views/Wetlab/ModelViews/Kit.vue'
import Patient from '../views/Wetlab/ModelViews/Patient.vue'
import Plasma from '../views/Wetlab/ModelViews/Plasma.vue'
import Plate from '../views/Wetlab/ModelViews/Plate.vue'
import Rack from '../views/Wetlab/ModelViews/Rack.vue'
import Shipment from '../views/Wetlab/ModelViews/Shipment.vue'
import Tube from '../views/Wetlab/ModelViews/Tube.vue'
import Wholeblood from '../views/Wetlab/ModelViews/Wholeblood.vue'


export default [
  {
    path: `facility:pk`,
    name: 'facility',
    component: Facility
  },
  {
    path: `kit:pk`,
    name: 'kit',
    component: Kit
  },
  {
    path: `patient:pk`,
    name: 'patient',
    component: Patient
  },
  {
    path: `plasma:pk`,
    name: 'plasma',
    component: Plasma
  },
  {
    path: `plate:pk`,
    name: 'plate',
    component: Plate
  },
  {
    path: `rack:pk`,
    name: 'rack',
    component: Rack
  },
  {
    path: `shipment:pk`,
    name: 'shipment',
    component: Shipment
  },
  {
    path: `tube:pk`,
    name: 'tube',
    component: Tube
  },
  {
    path: `wholeblood:pk`,
    name: 'wholeblood',
    component: Wholeblood
  }
]
