<template>
  <div>
    <!-- Only the router-view, no navbar or footer -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Blank'
};
</script>
