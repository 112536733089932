import ExternalAuth from '@/views/External/CollectionSites/ExternalAuth.vue'
import CollectionSitesHome from '@/views/External/CollectionSites/CollectionSitesHome.vue'
import ReceiveKits from '@/views/External/CollectionSites/ReceiveKits.vue'


export default [
  {
    path: `collectionsites`,
    component: ExternalAuth,
    name: 'externalauth'
  },
  {
    path: `collectionsites/home`,
    component: CollectionSitesHome,
    name: 'collectionsiteshome'
  },
  {
    path: `collectionsites/markkitsreceived`,
    component: ReceiveKits
  }
]

