import PreAmpHome from '../views/Wetlab/PreAmp/PreAmpHome.vue'
import MethylationAssayJob from '../views/Wetlab/PreAmp/MethylationAssayJob.vue'


export default [
  {
    path: `preamp`,
    name: 'preamp',
    component: PreAmpHome
  },
  {
    path: `preamp/methylationassayjob:pk`,
    name: 'methylationassayjob',
    component: MethylationAssayJob
  },
]