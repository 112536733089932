<template>
    <div v-if="hasContent" class="response-section">
        <div v-if="responseMessage" class="response-message">{{ responseMessage }}</div>
        <div v-if="responseError" class="error-message">{{ responseError }}</div>
        <Table v-if="tableComponentExists" :block="tableData" class="gray-table"/>
    </div>
</template>

<script>
import Table from './Table.vue';

export default {
    name: 'ResponseComponent',
    props: {
        responseMessage: {
            type: String,
            default: ''
        },
        responseError: {
            type: String,
            default: ''
        },
        tableComponentExists: {
            type: Boolean,
            default: false
        },
        tableData: {
            type: Object,
            default: () => ({})
        },
    },
    components: {
        Table
    },
    computed: {
        hasContent() {
            return (
                this.responseMessage ||
                this.responseError ||
                this.tableComponentExists
            );
        }
    }
};
</script>

<style scoped>
.response-section{
    border: 1px solid #ddd;
}
.response-message {
    font-size: 20px;
    color: green;
    background-color: rgb(249, 250, 251);
    padding: 20px;
}
.error-message {
    font-size: 20px;
    color: red;
    background-color: rgb(249, 250, 251);
    padding: 20px;
}
.gray-table {
    background-color: rgb(249, 250, 251); /* Light gray background */
    padding: 20px; /* Optional padding */
}
</style>
