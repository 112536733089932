import AccessionHome from '../views/Wetlab/Accession/AccessionHome.vue'
import HccEccOnboarding from '../views/Wetlab/Accession/HccEccOnboarding.vue'


export default [
  {
    path: `accession`,
    name: 'accession',
    component: AccessionHome
  },
  {
    path: `accession/hccecc_onboarding`,
    name: 'hccecc_onboarding',
    component: HccEccOnboarding
  }
]
