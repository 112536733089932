<template>
    <div class="page-search">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Search</h1>
                <h2 class="is-size-5 has-text-grey">Search term: "{{ query }}"</h2>
            </div>

            <!-- Loader -->
            <div v-if="isLoading" class="column is-12 has-text-centered">
                <div class="loading-symbol"></div> <!-- You can style this div or use a CSS loader -->
            </div>

            <!-- Display search results when not loading -->
            <component v-for="block in pageElements" :key="block.index" :is="block.component" :block="block" v-else></component>
        </div>
    </div>
</template>

<script>
import ActionMixin from '@/mixins/ActionMixin.js';
import axios from 'axios';

export default {
    name: 'Search',
    data() {
        return {
            pageElements: [],
            query: '',
            isLoading: false,
        }
    },
    mounted() {
        document.title = 'Search | Curve Biosciences'

        let uri = window.location.search.substring(1)
        let params = new URLSearchParams(uri)

        if (params.get('query')) {
            this.query = params.get('query')

            this.performSearch()
        }
    },
    methods: {
        async performSearch() {
            this.isLoading = true;
            await axios
                .post(`${process.env.VUE_APP_WETLAB_URL}/search/`, {'query': this.query}, {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('authToken')}`
                    }
                })
                .then(response => {
                    this.pageElements = response.data.body;
                    this.isLoading = false;
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        }
    }
}
</script>
