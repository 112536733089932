<template>
  <div>
    <p v-if="isLoading">Logging in...</p>
    <p v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AuthCallback',
  data() {
    return {
      isLoading: true,
      error: null,
      user: null,
    };
  },
  mounted() {
    document.title = 'Auth Callback'

    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const error = queryParams.get('error');
    const state = queryParams.get('state');

    if (code) {

      // Call the backend to exchange the code for tokens
      axios.get(`${process.env.VUE_APP_WETLAB_URL}/auth/login/google/`, {params: { code, state, error }})

        .then(response => {
          console.log('Login successful');

          localStorage.setItem('authToken', response.data.token);
          this.isLoading = false;
          this.$router.push({ name: 'wetlabhome' })
            .then(() => {
              window.location.reload();
            });

        })
        .catch(error => {
          console.error('Login failed:', error);
          this.error = 'Login failed. Please try again.';
          this.isLoading = false;
        });
    } else if (error) {
      console.error('Google login error:', error);
      this.error = `Google login error: ${error}`;
      this.isLoading = false;
    } else {
      this.error = 'No code or error returned from Google.';
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
p {
  font-size: 16px;
}
</style>
