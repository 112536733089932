export const DisableButtonMixin = {
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.disableButtons();
  },
  updated() {
    this.disableButtons();
  },
  methods: {
    disableButtons() {
      this.$nextTick(() => {
        if (this.$el && this.$el instanceof HTMLElement) {
          // Select all buttons except "download-button"
          const buttons = this.$el.querySelectorAll('button:not(.download-button)');
          if (buttons.length > 0) {
            buttons.forEach(button => {
              button.disabled = this.isLoading;
            });
          }
        } else {
          console.error('this.$el is not a valid DOM element:', this.$el);
        }
      });
    }
  }
};
