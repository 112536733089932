<template>
  <div>
    <template v-for="(item, index) in block.items" :key="index">
      <div v-if="item.type === 'text-box'">
        <input 
          type="text" 
          v-model="formData[item.variable_name]" 
          :placeholder="item.title" 
          :ref="item.variable_name" 
          @focus="initializeAutocomplete(item.variable_name, index)"
        >
        <div style="height: 12px;"></div>
      </div>

    </template>
    <button @click="submitForm">Submit</button>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formData: {},  // Object to store form data dynamically
      selectedFiles: {}  // Object to store selected files for uploadcsv type
    };
  },
  methods: {
    initializeAutocomplete(variable_name, index) {
      // Access the correct input element in the $refs array using index
      const inputElement = this.$refs[variable_name][index];

      if (inputElement && inputElement instanceof HTMLInputElement) {
        const autocomplete = new google.maps.places.Autocomplete(inputElement, {
          types: ['address'], // Limit the suggestions to addresses
        });

        // Listen for the place changed event and update the formData
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place && place.address_components) {
            this.extractAddressComponents(place.address_components);
          }
        });
      } else {
        console.error(`Invalid input element for Autocomplete: ${variable_name}`);
      }
    },

    extractAddressComponents(addressComponents) {
      // Map Google Places address components to your form fields
      addressComponents.forEach(component => {
        const componentType = component.types[0];
        switch (componentType) {
          case 'street_number':
            this.formData.street_line = component.long_name;
            break;
          case 'locality':
            this.formData.city = component.long_name;
            break;
          case 'administrative_area_level_1':
            this.formData.state = component.short_name;
            break;
          case 'country':
            this.formData.country = component.long_name;
            break;
          case 'postal_code':
            this.formData.zipcode = component.long_name;
            break;
          default:
            break;
        }
      });
    },

    submitForm() {
      // Handle form submission as usual
      const combinedData = { ...this.formData, ...this.selectedFiles };
      combinedData['action'] = this.block.action;
      this.$emit('formSubmitted', combinedData);
    }
  }
};
</script>
