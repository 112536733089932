import PostAmpHome from '../views/Wetlab/PostAmp/PostAmpHome.vue'


export default [
  {
    path: `postamp`,
    name: 'postamp',
    component: PostAmpHome
  },
]
