import FulfillmentHome from '../views/Wetlab/Fulfillment/FulfillmentHome.vue'
import FacilityList from '../views/Wetlab/Fulfillment/FacilityList.vue'
import KitList from '../views/Wetlab/Fulfillment/KitList.vue'
import ShipmentList from '../views/Wetlab/Fulfillment/ShipmentList.vue'
import InitiateKits from '../views/Wetlab/Fulfillment/InitiateKits.vue'
import AssembleKits from '../views/Wetlab/Fulfillment/AssembleKits.vue'
import MailKits from '../views/Wetlab/Fulfillment/MailKits.vue'
import NewFacility from '../views/Wetlab/Fulfillment/NewFacility.vue'


export default [
  {
    path: `fulfillment`,
    name: 'fulfillment',
    component: FulfillmentHome
  },
  {
    path: `fulfillment/facilitylist`,
    name: 'facilitylist',
    component: FacilityList
  },
  {
    path: `fulfillment/kitlist`,
    name: 'kitlist',
    component: KitList
  },
  {
    path: `fulfillment/shipmentlist`,
    name: 'shipmentlist',
    component: ShipmentList
  },
  {
    path: `fulfillment/initiatekits`,
    name: 'initiatekits',
    component: InitiateKits
  },
  {
    path: `fulfillment/assemblekits`,
    name: 'assemblekits',
    component: AssembleKits
  },
  {
    path: `fulfillment/mailkits`,
    name: 'mailkits',
    component: MailKits
  },
  {
    path: `fulfillment/newfacility`,
    name: 'newfacility',
    component: NewFacility
  }
]
