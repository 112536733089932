<template>
  <div>
    <ul>
      <li v-for="(item, index) in block.items" :key="index">
        <a :href="item[1]">{{ item[0] }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true
    }
  }
};
</script>
