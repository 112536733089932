<template>
  <div :style="gridStyles" class="grid">
    <div v-for="row in block.rows" :key="row" class="row">
      <div v-for="col in block.columns" :key="col" class="cell" :class="{ occupied: isOccupied(row, col) }" style="font-size: 12px;">
        {{ row }}{{ col }}
        <div v-if="isOccupied(row, col)">
          <br>
          <router-link :to="getTube(row, col).url" style="font-size: 12px;">{{ getTube(row, col).name }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  computed: {
    gridStyles() {
      return {
        display: 'grid',
        'grid-template-rows': `repeat(${this.block.row_number}, 1fr)`,
        'grid-template-columns': `repeat(${this.block.column_number}, 100px)`,
        'grid-column-gap': '0',
        'grid-row-gap': '0'
      };
    }
  },
  methods: {
    isOccupied(row, col) {
      return this.block.tubes.some(tube => tube.row === row && tube.column === col);
    },
    getTube(row, col) {
      return this.block.tubes.find(tube => tube.row === row && tube.column === col);
    },
  }
};
</script>

<style scoped>
.row {
  display: contents;
}
.cell {
  border: 1px solid #ccc;
  display: flex;
  align-items: left;
  justify-content: left;
  height: 60px;
  width: 100px;
}
.cell.occupied {
  background-color: #aaffaa;
}
</style>
