import SampleBatchingHome from '../views/Wetlab/SampleBatching/SampleBatchingHome.vue'
import MethylationManual from '../views/Wetlab/SampleBatching/MethylationManual.vue'


export default [
  {
    path: `samplebatching`,
    name: 'samplebatching',
    component: SampleBatchingHome
  },
  {
    path: `samplebatching/methylationmanual`,
    name: 'samplebatching/methylationmanual',
    component: MethylationManual
  },
]
