<template>
  <div>
    <div v-if="isLoading" class="loading-symbol"></div>

    <ResponseComponent
      :responseMessage="responseMessage"
      :responseError="responseError"
      :tableComponentExists="tableComponentExists"
      :tableData="tableData"
    />
    
  </div>
  <div class="container">
    <div class="left-column">
      <component 
        v-for="block in pageElements" 
        :key="block.index" 
        :is="block.component" 
        :block="block" 
        @formSubmitted="submitForm"
      ></component>
    </div>
    <div class="right-column">
      <component 
        v-for="block in pageElementsRight" 
        :key="block.index" 
        :is="block.component" 
        :block="block" 
        @formSubmitted="submitForm"
      ></component>
    </div>
    
  </div>
</template>

<script>
import ActionMixin from '@/mixins/ActionMixin.js'

export default {
  mixins: [ActionMixin],
  mounted() {
    document.title = `Tube | tube${this.$route.params.pk}`
  },
  created() {
    this.fetchPageStructure(`${process.env.VUE_APP_WETLAB_URL}/tube${this.$route.params.pk}/`);
  },
  methods: {
    submitForm(formData) {
      this.handleFormSubmission(`${process.env.VUE_APP_WETLAB_URL}/tube${this.$route.params.pk}/`, formData);
    },
  }
};
</script>
