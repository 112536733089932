<template>
  <BaseTable :block="block" />
</template>

<script>
import BaseTable from './BaseTable.vue';

export default {
  components: {
    BaseTable
  },
  props: {
    block: {
      type: Object,
      required: true
    }
  }
};
</script>
