<template>
    <div>
        <div v-if="!isLoggedIn">
            <p>Login here with your CurveBio Google Account.</p>
            <button @click="onGoogleLoginSuccess">Sign in with Google</button>
        </div>
        <div v-else>
            <p>You are already logged in!</p>
            <button @click="onLogout">Logout</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'GoogleLogin',
    data() {
        return {
            authorizationUrl: '',
            user: '',
            isLoggedIn: localStorage.getItem('authToken') ? true : false,
        };
    },
    mounted() {
        document.title = 'Login | Curve Biosciences'
    },
    created() {
        axios.get(`${process.env.VUE_APP_WETLAB_URL}/auth/config/`)
            .then(response => {
                this.authorizationUrl = response.data.authorizationUrl;
                this.user = response.data.user;
            })
            .catch(error => {
                console.error("There was an error fetching the Google auth configuration:", error);
            });
    },

    methods: {
        onGoogleLoginSuccess() {
            window.location = this.authorizationUrl;
        },
        onLogout() {
            axios.get(`${process.env.VUE_APP_WETLAB_URL}/auth/logout/`, {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                }
            })
            .then(response => {
                localStorage.removeItem('authToken');
                this.isLoggedIn = false;
                window.location.reload(); // Reload the page or handle post-logout behavior
            })
            .catch(error => {
                localStorage.removeItem('authToken');
                this.isLoggedIn = false;
                console.error("There was an error logging out:", error);
            });
        }
    },
};
</script>
